<template>
  <div>
    <template v-if="exam_info.exam_date">
      <div class="box">
        <div style="width:1200px;margin: -20px auto 30px;" class="text-right">
          <Breadcrumb :customBreadcrumbList="[{ name: 'ToeflTestResults' }]">
            <template slot="restPage">
              <el-breadcrumb-item :to="{ name: 'toeflResult', query: $route.query }">
                {{ exam_info.tpo }} {{ exam_info.exam_title }}
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                {{ exam_info.exam_title }} PDF
              </el-breadcrumb-item>
            </template>
          </Breadcrumb>
          <el-button
            type="success"
            style="margin-top: 20px"
            @click="
              downloadPDF(
                '#content',
                exam_info.student.last_name +
                  ', ' +
                  exam_info.student.first_name +
                  '  ' +
                  exam_info.exam_title
              )
            "
          >
            <i class="fas fa-download"></i>
            {{ $t("button.downloadTranscript") }}
          </el-button>
        </div>
        <div
          id="content"
          v-loading="loading"
          element-loading-text="Generating PDF file..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <PDFPage>
            <div class="row sat" style="margin-top:0 ">
              <div class="col-md-8 col-sm-12 sat_right">
                <ul>
                  <li>
                    <b>Name: </b>
                    <span style="font-size:22px">
                      {{
                        exam_info.student.last_name +
                          ", " +
                          exam_info.student.first_name
                      }}
                    </span>
                    <div style="padding-left:55px">
                      <small>
                        Last (Family/Surname) Name, First (Given) Name Middle
                        Name
                      </small>
                    </div>
                  </li>
                  <li>
                    <b>Email: </b>
                    <span>{{ exam_info.student.email }}</span>
                  </li>
                  <li>
                    <b>Test Name: </b>
                    <span> {{ exam_info.exam_title }}</span>
                  </li>
                </ul>
                <div class="address">
                  <p>
                    {{
                      exam_info.student.last_name +
                        ", " +
                        exam_info.student.first_name
                    }}
                  </p>
                  <p>
                    Ivy-way
                  </p>
                  <p>
                    Online
                  </p>
                </div>
                <ul>
                  <li><b>Test Center: </b> <span>Ivyway</span></li>
                  <li><b>Test Center Country: </b> <span>Online</span></li>
                </ul>
              </div>
              <div class="col-md-4 col-sm-12 sat_left">
                <div class="photo-user">
                  <img
                    v-if="exam_info.student.photo_url"
                    id="infoImg"
                    :src="getImage(exam_info.student.photo_url, 'infoImg')"
                    alt=""
                  />
                  <img v-else src="@/assets/avatar.png" alt="" />
                </div>
                <table
                  class="table table-bordered table-sm"
                  style="width:300px;margin: 20px auto"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th>Inst. Code</th>
                      <th>Dept. Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>00000</td>
                      <td>00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <el-divider>Security Identification</el-divider>
            <div>
              <span style="margin-right:160px">
                <b>ID Type: </b>
                <span>Ivy Way ID</span>
              </span>
              <span style="margin-right:160px">
                <b>ID No.: </b>
                <span>{{ exam_info.student.id }}</span>
              </span>
              <span style="margin-right:160px">
                <b>Issuing Country: </b>
                <span>Ivy Way</span>
              </span>
            </div>
            <div style="margin:20px 0">
              <b>
                THIS IS A PDF SCORE REPORT, DOWNLOADED AND PRINTED BY THE TEST
                TAKER.
              </b>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 user-scores">
                <score-card
                  :user_score="user_score"
                  :id="user_exam_id"
                  :scoreDate="exam_info.exam_date"
                  :reading="!isEmpty(reading_list)"
                  :listening="!isEmpty(listening_list)"
                  :speaking="!isEmpty(speaking_list)"
                  :writing="!isEmpty(writing_list)"
                ></score-card>
              </div>
              <div class="col-sm-12 col-md-6">
                <best-score-card
                  :user_score="user_score"
                  :isPDF="true"
                ></best-score-card>
              </div>
            </div>
            <div>
              <p class="text-center">
                <i
                  >A total score is not reported when one or more sections have
                  not been administered.</i
                >
                <br />
                Expired scores are not included in
                <b><i>MyBest&#8482;</i></b> calculatio
              </p>
            </div>
          </PDFPage>
          <PDFPage>
            <div style="margin:20px 0">
              <b>
                THIS IS A PDF SCORE REPORT, DOWNLOADED AND PRINTED BY THE TEST
                TAKER.
              </b>
            </div>
            <div class="row">
              <div class="col-md-6">
                <h4>
                  {{
                    exam_info.student.last_name +
                      ", " +
                      exam_info.student.first_name
                  }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" style="border-right:4px solid #797474">
                <h6>SCORE RANGES</h6>
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th>Total Score</th>
                      <th>0 - 120</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Reading</th>
                      <th>0 - 30</th>
                    </tr>
                    <tr>
                      <td>Advanced</td>
                      <td>24 - 30</td>
                    </tr>
                    <tr>
                      <td>High - Intermediate</td>
                      <td>18 - 23</td>
                    </tr>
                    <tr>
                      <td>Low - Intermediate</td>
                      <td>4 - 17</td>
                    </tr>
                    <tr>
                      <td>Below Low - Intermediater</td>
                      <td>0 - 3</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Listening</th>
                      <th>0 - 30</th>
                    </tr>
                    <tr>
                      <td>Advanced</td>
                      <td>22 - 30</td>
                    </tr>
                    <tr>
                      <td>High - Intermediate</td>
                      <td>17 - 21</td>
                    </tr>
                    <tr>
                      <td>Low - Intermediate</td>
                      <td>9 - 16</td>
                    </tr>
                    <tr>
                      <td>Below Low - Intermediater</td>
                      <td>0 - 8</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Speaking</th>
                      <th>0 - 30</th>
                    </tr>
                    <tr>
                      <td>Advanced</td>
                      <td>25 - 30</td>
                    </tr>
                    <tr>
                      <td>High - Intermediate</td>
                      <td>20 - 24</td>
                    </tr>
                    <tr>
                      <td>Low - Intermediate</td>
                      <td>16 - 19</td>
                    </tr>
                    <tr>
                      <td>Basic</td>
                      <td>10 - 15</td>
                    </tr>
                    <tr>
                      <td>Below Basic</td>
                      <td>0 - 9</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Speaking</th>
                      <th>0 - 30</th>
                    </tr>
                    <tr>
                      <td>Advanced</td>
                      <td>24 - 30</td>
                    </tr>
                    <tr>
                      <td>High - Intermediate</td>
                      <td>17 - 23</td>
                    </tr>
                    <tr>
                      <td>Low - Intermediate</td>
                      <td>13 - 16</td>
                    </tr>
                    <tr>
                      <td>Basic</td>
                      <td>7 - 12</td>
                    </tr>
                    <tr>
                      <td>Below Basic</td>
                      <td>0 - 6</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <h6>INSTITUTION CODES</h6>
                <p>
                  The Institutions and Department code numbers shown on the
                  front page are the ones you selected before you took the test.
                </p>
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th style="width:150px">Dept.</th>
                      <th>Where the Report Was Sent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>00</td>
                      <td>Admissions office for undergraduate study</td>
                    </tr>
                    <tr>
                      <td>01, 04-41,43-98</td>
                      <td>
                        Admissions office for graduate study in a field other
                        than management (business) or law according to the codes
                        selected when you registered
                      </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>
                        Admissions office of a graduate school of management
                        (business)
                      </td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>Admissions office of a graduate school of law</td>
                    </tr>
                    <tr>
                      <td>42</td>
                      <td>
                        Admissions office of a school of medicine or nursing or
                        licensing agency
                      </td>
                    </tr>
                    <tr>
                      <td>99</td>
                      <td>
                        Institution or agency that is not a college or
                        university
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </PDFPage>
          <PDFPage>
            <score-table
              :user_evaluation="user_evaluation"
              :user_score="user_score"
            ></score-table>
          </PDFPage>
          <PDFPage v-if="!isEmpty(reading_list)">
            <div>
              <reading
                :list="reading_list"
                :score="user_score.reading_score"
                :raw="user_score.reading_raw"
                :count="user_score.reading_question_count"
              ></reading>
            </div>
          </PDFPage>
          <PDFPage v-if="!isEmpty(listening_list)">
            <div>
              <listening
                :list="listening_list"
                :score="user_score.listening_score"
                :raw="user_score.listening_raw"
                :count="user_score.listening_question_count"
              ></listening>
            </div>
          </PDFPage>
          <PDFPage v-if="!isEmpty(speaking_list)">
            <div>
              <Speaking
                :score="user_score.speaking_score"
                :list="speaking_list"
                :examInfo="exam_info"
                :isPDF="true"
              />
            </div>
          </PDFPage>
          <PDFPage v-if="!isEmpty(writing_list) && writing_list[0]">
            <div>
              <Writing
                :score="user_score.writing_score"
                :list="[writing_list[0]]"
                :examInfo="exam_info"
                :isPDF="true"
              />
            </div>
          </PDFPage>
          <PDFPage v-if="!isEmpty(writing_list) && writing_list[1]">
            <div>
              <Writing
                :score="user_score.writing_score"
                :list="[writing_list[1]]"
                :examInfo="exam_info"
                :isPDF="true"
              />
            </div>
          </PDFPage>
          <Lock
            ref="lock"
            :userExamId="user_exam_id"
            :examInfo="exam_info"
            :pointPackages="pointPackages"
          />
        </div>
      </div>
    </template>
    <div v-else>
      <div class="text-center" style="height:100%; height: 100vh; padding:30px 0;background: #f2f3f5">
        <el-alert
          style="width:200px;margin:0 auto"
          :title="$t('toefl.testLoading')"
          type="success"
          :closable="false"
          effect="dark"
        >
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
import TOEFL from "@/apis/toefl";
import "./style/reportcard.css";
import role from "@/mixins/role.js";
import _ from "lodash";
import ScoreCard from "./ScoreCard";
import BestScoreCard from "./BestScoreCard";
import ScoreTable from "./ScoreTable";
import Reading from "./Reading";
import Listening from "./Listening";
import Speaking from "./Speaking";
import Writing from "./Writing";
import Lock from "./components/Lock";
import Breadcrumb from "@/components/Breadcrumb";
import PDFPage from "@/views/toefl/transcript/PDFPage.vue";

export default {
  metaInfo() {
    return {
      title: "TOEFL Test Result - " + this.CompanyName
    };
  },

  components: {
    // Breadcrumb,
    ScoreCard,
    BestScoreCard,
    ScoreTable,
    Reading,
    Listening,
    Speaking,
    Writing,
    Lock,
    Breadcrumb,
    PDFPage
  },

  mixins: [role],

  props: [],
  data() {
    return {
      user_evaluation: {},
      user_score: {},
      topic: [],
      exam_info: { student: { first_name: "", last_name: "" } },
      pointPackages: [],
      updateAnswers: [],
      timer: null,
      speaking_list: null,
      writing_list: null,
      loading: false
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    user_exam_id() {
      return this.$route.query.id;
    },
    reading_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "reading"]),
        "passage"
      );
    },
    listening_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "listening"]),
        "passage"
      );
    },
    canSave() {
      let scoreCount = 0;
      if (this.updateAnswers.length > 0) {
        this.updateAnswers.forEach(item => {
          if (item.score > 0) {
            scoreCount++;
          }
          if (item.suggest != "") {
            scoreCount++;
          }
        });
      }
      return scoreCount > 0;
    }
  },
  watch: {
    speaking_list: {
      handler(nV, oV) {
        if (oV && oV.length > 0) {
          if (!this.timer && this.isAdmin) {
            this.timer = setTimeout(() => {
              this.saveTranscript("automatic");
              clearInterval(this.timer);
              this.timer = null;
            }, 2000);
          }
        }
      },
      deep: true
    },
    writing_list: {
      handler(nV, oV) {
        if (oV && oV.length > 0) {
          if (!this.timer && this.isAdmin) {
            this.timer = setTimeout(() => {
              this.saveTranscript("automatic");
              clearInterval(this.timer);
              this.timer = null;
            }, 2000);
          }
        }
      },
      deep: true
    }
  },

  mounted() {
    this.getTranscript();
    this.getPointPackage();
  },

  methods: {
    getImage(url, imgId) {
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "blob";
      xhr.onload = function() {
        if (this.status == 200) {
          document.getElementById(imgId).src = URL.createObjectURL(
            this.response
          );
        }
      };
      xhr.send(null);
    },
    downloadPDF(ele, title) {
      this.loading = true;
      this.getPdf(ele, title);
    },
    async sendEmail() {
      await this.$alert("Do you want to send a notification of completion of correction to students?", "Send email", {
        confirmButtonText: "Yes",
        callback: action => {
          const res = TOEFL.sendEmail(this.user_exam_id);
          this.$message({
            message: "Success!",
            type: "success"
          });
        }
      });
    },
    getArr(arr) {
      arr.forEach(val => {
        let newVal = {
          id: val.user_exam_answer_id,
          score: val.score,
          suggest: val.suggest
        };
        this.updateAnswers.push(newVal);
      });
    },
    async saveTranscript(text = "") {
      this.updateAnswers = [];
      this.getArr(this.speaking_list);
      this.getArr(this.writing_list);
      if (this.canSave) {
        await TOEFL.updateTranscript(this.user_exam_id, {
          user_exam_answers: this.updateAnswers
        });
        this.$notify({
          title: text + "success！",
          type: "success",
          position: "bottom-right",
          duration: "1000"
        });
      }
      // this.getTranscript();
    },
    getAlert() {
      this.$refs.lock.getAlert();
    },
    async getTranscript() {
      const res = await TOEFL.viewPdf(this.user_exam_id);
      // const res = await TOEFL.getTranscript(this.user_exam_id);
      this.user_evaluation = res.user_evaluation;
      this.user_score = res.user_score;
      this.topic = res.topic;
      this.exam_info = res.exam_info;
      let speaking_list = _.filter(this.topic, ["skill_name", "speaking"]);
      speaking_list.forEach(element => {
        this.$set(element, "canPlay", true);
        this.$set(element, "width", 0);
        this.$set(element, "progress", 0);
        this.$set(element, "time", 60);
        this.$set(element, "speed", 1);
      });
      this.speaking_list = JSON.parse(JSON.stringify(speaking_list));
      let writing_list = _.filter(this.topic, ["skill_name", "writing"]);
      this.writing_list = JSON.parse(JSON.stringify(writing_list));
    },
    async getPointPackage() {
      const res = await TOEFL.getPointPackage(this.user_exam_id);
      if (res.point_packages) {
        if (res.point_packages.length > 0) {
          res.point_packages.forEach(val => {
            val["selected"] = false;
          });
          this.pointPackages = res.point_packages;
        }
      }
    },
    isEmpty(object) {
      return _.isEmpty(object);
    },
    getResolve(question_id) {
      const { href } = this.$router.resolve({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id: question_id
        }
      });
      return href;
      // window.open(href, "_blank");
    },
    async saveScore(user_exam_answer_id, score) {
      await TOEFL.gradeScore(user_exam_answer_id, {
        score: score
      });
      this.getTranscript();
    }
  }
};
</script>

<style scoped src="./style/reportcard.css"></style>
<style scoped>
.box {
  background: #f2f3f5;
  padding: 20px 0;
}
.footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  padding: 0 40px;
}
#content {
  width: 1200px;
  margin: 0 auto;
}
.content {
  height: 1705.6px;
  overflow: hidden;
  padding: 40px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid #cfcfcf;
}
hr.section {
  border: 0;
  border-bottom: 3px solid var(--themeColor);
  margin: 20px 0;
}
.reportcard {
  padding: 20px;
}
.sat_left > p:first-child {
  font-size: 120px;
  font-weight: 700;
  color: var(--themeColor);
  line-height: 120px;
  margin-bottom: 0px;
}
.tip {
  padding: 8px 16px;
  background-color: #42a16a40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}
.header {
  display: flex;
  text-align: right;
}
.img img {
  height: 60px;
}
.title {
  margin-left: 20px;
  font-weight: 700;
  font-size: 2rem;
  line-height: 70px;
  color: var(--themeColor);
  font-style: italic;
}
.title-right {
  font-size: 25px;
  font-weight: 500;
  line-height: 70px;
  flex: 1;
}
.header-hr {
  border-bottom: 5px solid var(--themeColor);
  margin: 10px 0 10px 0;
}
.sat_right {
  padding-top: 0;
  border-bottom: none;
}
.sat_right ul li {
  font-size: 16px;
  margin: 0 0 10px 0;
}
.photo-user {
  width: 300px;
  height: 300px;
  margin: 10px auto 0;
  overflow: hidden;
}
.photo-user img {
  width: 100%;
}
::v-deep .el-loading-spinner {
  top: 200px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  text-align: left;
}
.table .thead-dark th {
  color: #fff;
  background-color: #797474;
  border-color: #797474;
}
.address {
  height: 200px;
  border: 1px dashed #dcdfe6;
  padding: 40px;
  margin: 10px 0;
}
.address p {
  font-size: 20px;
  margin: 0;
}
::v-deep .el-divider {
  background: #fff;
  border-bottom: 1px dashed #dcdfe6;
}
@media screen and (max-width: 736px) {
  .sat_left > p:first-child {
    font-size: 60px;
    line-height: 60px;
    /* text-align: center; */
  }
  .sat_right {
    padding: 0 0 0 0;
    border: none;
  }
  .sat_right .img {
    display: none;
  }
  .sat_right ul li {
    font-size: 16px;
  }
  .user-scores {
    margin-bottom: 20px;
  }
}
</style>
