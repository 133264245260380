<template>
  <div class="content">
    <div class="header">
      <!-- <div class="img">
        <img src="@/assets/logo.png" class="logo logo_image" />
      </div> -->
      <div class="logo">
        <h2>
          TOEFL
        </h2>
        <span class="toefl-r">&reg;</span>
        <h2>
                TestAdmit</h2>
      </div>

      <div class="title">
        TOEFL iBT<span style="font-size:16px">&copy;</span>
      </div>
      <div class="title-right">Test Taker Score Report</div>
    </div>
    <hr class="header-hr" />
    <div style="padding-top:10px">
      <slot></slot>
    </div>
    <div class="footer">
      <hr class="header-hr" />
      <p>
        {{ rights }}
      </p>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      rights: ""
    };
  },
  computed: {},
  watch: {},

  mounted() {
    const year = moment().year();
    this.rights =
      "© 2020-" +
      year +
      "TestAdmit. All rights reserved. TestAdmit is not affiliated with or endorsed by ETS® or TOEFL®.";
  },

  methods: {
    getResolve(question_id) {
      return this.$parent.getResolve(question_id);
    }
  }
};
</script>

<style scoped>
.logo h2 {
  color: var(--themeColor);
  font-weight: 700;
  display: inline-block;
  line-height: 70px;
}
.toefl-r {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  margin-right: 5px;
}
.box {
  background: #f2f3f5;
  padding: 20px 0;
}
.footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  padding: 0 40px;
}
#content {
  width: 1200px;
  margin: 0 auto;
}
.content {
  height: 1705.6px;
  overflow: hidden;
  padding: 40px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid #cfcfcf;
}
hr.section {
  border: 0;
  border-bottom: 3px solid var(--themeColor);
  margin: 20px 0;
}
.reportcard {
  padding: 20px;
}
.sat_left > p:first-child {
  font-size: 120px;
  font-weight: 700;
  color: var(--themeColor);
  line-height: 120px;
  margin-bottom: 0px;
}
.tip {
  padding: 8px 16px;
  background-color: #42a16a40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}
.header {
  display: flex;
  text-align: right;
}
.img img {
  height: 60px;
}
.title {
  margin-left: 20px;
  font-weight: 700;
  font-size: 45px;
  line-height: 70px;
  color: var(--themeColor);
  font-style: italic;
}
.title-right {
  font-size: 25px;
  font-weight: 500;
  line-height: 70px;
  flex: 1;
}
.header-hr {
  border-bottom: 5px solid var(--themeColor);
  margin: 10px 0 10px 0;
}
.sat_right {
  padding-top: 0;
  border-bottom: none;
}
.sat_right ul li {
  font-size: 16px;
  margin: 0 0 10px 0;
}
.photo {
  width: 200px;
  height: 200px;
  margin: 10px auto 0;
  border: 3px solid var(--themeColor);
  border-radius: 10px;
  overflow: hidden;
}
.photo img {
  width: 100%;
  height: 100%x;
}
::v-deep .el-loading-spinner {
  top: 10%;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  text-align: left;
}
.table .thead-dark th {
  color: #fff;
  background-color: #797474;
  border-color: #797474;
}
.address {
  height: 200px;
  border: 1px dashed #dcdfe6;
  padding: 40px;
  margin: 10px 0;
}
.address p {
  font-size: 20px;
  margin: 0;
}
::v-deep .el-divider {
  background: #fff;
  border-bottom: 1px dashed #dcdfe6;
}
@media screen and (max-width: 736px) {
  .sat_left > p:first-child {
    font-size: 60px;
    line-height: 60px;
    /* text-align: center; */
  }
  .sat_right {
    padding: 0 0 0 0;
    border: none;
  }
  .sat_right .img {
    display: none;
  }
  .sat_right ul li {
    font-size: 16px;
  }
  .user-scores {
    margin-bottom: 20px;
  }
}
</style>
